import React from 'react';
import { Button } from 'antd';
import { useAuth } from '../../hooks/auth';
import 'antd/dist/antd.css';
import './index.css';

const Exit: React.FC = () => {
  const { signOut } = useAuth();
  return (
    <Button
      style={{
        marginLeft: '87%',
        color: '#fff',
        padding: 0,
        fontSize: 16,
        backgroundColor: '#002512',
        border: 0,
      }}
      onClick={signOut}
    >
      Sair
    </Button>
  );
};
export default Exit;
