import React from 'react';
import 'antd/dist/antd.css';
import { Table, Input, Button, Space, Select, Tooltip, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { CheckOutlined, SearchOutlined, RedoOutlined } from '@ant-design/icons';
import API from '../../services/api';
import Moment from 'react-moment';

export default class Resgate extends React.Component {

  state = {
    searchText: '',
    searchedColumn: '',
    resgates: [],
    loadings: [],
  };

  async componentDidMount() {
    try {
      const responseResgate = await API.get('/resgates/retaguarda');
      this.setState({ resgates: responseResgate.data });
    } catch (error) {
      console.log(error);
    }
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Pesquisar`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Limpar
          </Button>
          {/* <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button> */}
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleChange(value) {
  };

  async atualizaResgate(resgate) {
    try {

      await API.put('/resgates/' + resgate.id,
        {
          cpf_id: resgate.cpf_id,
          quantidade: resgate.quantidade,
          produto: resgate.produto,
          valor_unitario: resgate.valor_unitario,
          valor_total_resgatado: resgate.valor_total_resgatado,
          status: resgate.status,
          loja_retirada: resgate.loja_retirada,
        });

      const responsePonto = await API.get('/pontos/app?cpf_id=' + resgate.cpf_id);
      const idPonto = responsePonto.data.pontos[0].id

      if (resgate.status === 'Entregue') {
        await API.put('/pontos/' + idPonto,
          {
            cpf_id: resgate.cpf_id,
            pontos_ativos: 0,
            pontos_resgatados: resgate.valor_total_resgatado,
            pontos_expirados: 0,
            pontos_totais: 0,
            pontos_pendentes: -resgate.valor_total_resgatado,
            cpf_adm: '00000000000'
          });
      }

      if (resgate.status === 'Cancelado') {
        await API.put('/pontos/' + idPonto,
          {
            cpf_id: resgate.cpf_id,
            pontos_ativos: resgate.valor_total_resgatado,
            pontos_resgatados: 0,
            pontos_expirados: 0,
            pontos_totais: 0,
            pontos_pendentes: -resgate.valor_total_resgatado,
            cpf_adm: '00000000000'
          });
      }

      this.componentDidMount()
      message.success('Status Alterado com sucesso!')

    } catch (error) {
      message.error('Não foi possível alterar o status, verifique e tente novamente.')
      console.log(error);
    }
  }

  render() {

    const { Option } = Select;

    const columns = [
      {
        title: 'Data Solicitação',
        dataIndex: 'criado_em',
        key: 'criado_em',
        ...this.getColumnSearchProps('criado_em'),
        defaultSortOrder: 'descend',
        sorter: (a, b) => a.criado_em > b.criado_em,
        render: (text, resgate) =>
        (
          <>
            <Moment format="DD/MM/YYYY HH:mm">{resgate.criado_em}</Moment>
          </>
        ),
      },
      {
        title: 'CPF',
        dataIndex: 'cpf_id',
        key: 'cpf_id',
        ...this.getColumnSearchProps('cpf_id'),
      },
      {
        title: 'Produto',
        dataIndex: 'produto',
        key: 'produto',
        ...this.getColumnSearchProps('produto'),
      },
      {
        title: 'Quantidade',
        key: 'quantidade',
        dataIndex: 'quantidade',
        ...this.getColumnSearchProps('quantidade'),
      },
      {
        title: 'Valor Total',
        dataIndex: 'valor_total_resgatado',
        key: 'valor_total_resgatado',
        ...this.getColumnSearchProps('valor_total_resgatado'),
      },
      {
        title: 'Loja de Retirada',
        dataIndex: 'loja_retirada',
        key: 'loja_retirada',
        ...this.getColumnSearchProps('loja_retirada'),
      },
      {
        title: 'Última Atualização',
        dataIndex: 'atualizado_em',
        key: 'atualizado_em',
        ...this.getColumnSearchProps('atualizado_em'),
        render: (text, resgate) =>
        (
          <>
            <Moment format="DD/MM/YYYY HH:mm">{resgate.atualizado_em}</Moment>
          </>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        ...this.getColumnSearchProps('status'),
      },
      {
        title: 'Alterar Status',
        dataIndex: 'status',
        key: 'status',
        ...this.getColumnSearchProps('status'),

        render: (text, resgate) => (
          <>
            <Select defaultValue={resgate.status} style={{ width: 110 }} onChange={() => this.handleChange}>
              <Option value={() => resgate.status = 'Solicitado'}>Solicitado</Option>
              <Option value={() => resgate.status = 'Disponivel'}>Disponivel</Option>
              <Option value={() => resgate.status = 'Entregue'}>Entregue</Option>
              <Option value={() => resgate.status = 'Cancelado'}>Cancelado</Option>
            </Select>
          </>
        ),
      },
      {
        title: () => (
          <>
            <Tooltip title="Atualizar dados">
              <Button style={{ background: '#fdc000', border: 0 }} type="primary" icon={<RedoOutlined />} onClick={() => this.componentDidMount()} />
            </Tooltip>
          </>
        ),
        render: (text, resgate) => (
          <>
            <Tooltip title="Salvar">
              <Button style={{ background: '#00562a', border: 0 }} type="primary" icon={<CheckOutlined />} onClick={() => this.atualizaResgate(resgate)} />
            </Tooltip>
          </>
        ),
      },
    ];

    return (
      <>
        <Table columns={columns} dataSource={this.state.resgates} />
      </>
    );
  }
}
