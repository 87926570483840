import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Modal, Button, Tooltip, Card, Col, Row, Table, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import API from '../../services/api';
import Moment from 'react-moment';

const ModalExtrato = (info) => {

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pontos, setIsPontos] = useState('0');
  const [extrato, setIsExtrato] = useState('');

  const showModal = async () => {

    try {

      const responsePontos = await API.get('/pontos/app?cpf_id=' + info.info.cpf);
      if (responsePontos.data.pontos.length !== 0) {
        setIsPontos(responsePontos.data.pontos[0]);

        const responseExtrato = await API.get('/extrato?cpf_id=' + info.info.cpf);
        setIsExtrato(responseExtrato.data.extrato);

        setIsModalVisible(true);
      } else {
        message.warning('Cliente sem histórico de pontos, entre em contato e traga-o para o time Campelo Card', 2);
      }

    } catch (error) {
      message.error('Não foi possível resgatar os dados, verifique a conexão com servidor e tente novamente')
      console.log(error);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: 'Data',
      dataIndex: 'criado_em',
      key: 'criado_em',
      defaultSortOrder: 'descend',
      sorter: (a, b) => a.criado_em > b.criado_em,
      render: (text, resgate) =>
      (
        <>
          <Moment format="DD/MM/YYYY HH:mm">{resgate.criado_em}</Moment>
        </>
      ),
    },
    {
      title: 'Pontos',
      key: 'valor_pontos',
      dataIndex: 'valor_pontos',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'CPF Admin',
      dataIndex: 'cpf_adm',
      key: 'cpf_adm',
    },
  ];

  return (
    <>
      <Tooltip title="Detalhes">
        <Button style={{ background: '#00562a', border: 0 }} type="primary" icon={<SearchOutlined />} onClick={showModal} />
      </Tooltip>

      <Modal title="Histórico de Pontos" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={1000} style={{ top: 20 }}
        footer={[
          <Button style={{ background: '#00562a', border: 0 }} key="submit" type="primary" onClick={handleOk}>
            Ok
          </Button>]}>

        <div className="site-card-wrapper">
          <Row gutter={16}>
            <Col span={6}>
              <Card title="ATIVOS" style={{ textAlign: 'center', fontSize: 18 }} bordered={true} headStyle={{ background: '#0876FF98' }}>
                {pontos.pontos_ativos}
              </Card>
            </Col>
            <Col span={6}>
              <Card title="PENDENTES" style={{ textAlign: 'center', fontSize: 18 }} bordered={true} headStyle={{ background: '#fdc00098' }}>
                {pontos.pontos_pendentes}
              </Card>
            </Col>
            <Col span={6}>
              <Card title="RESGATADOS" style={{ textAlign: 'center', fontSize: 18 }} bordered={true} headStyle={{ background: '#00562a98' }}>
                {pontos.pontos_resgatados}
              </Card>
            </Col>
            <Col span={6}>
              <Card title="EXPIRADOS" style={{ textAlign: 'center', fontSize: 18 }} bordered={true} headStyle={{ background: '#FF1E0F98' }}>
                {pontos.pontos_expirados}
              </Card>
            </Col>
          </Row>
        </div>

        <Table columns={columns} dataSource={extrato} />

      </Modal>
    </>
  );
};

export default ModalExtrato;
