import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromLeft = keyframes`
from {
  opacity: 0;
  transform: translateX(-50px);
}

to {
  opacity: 1;
  transform: translateX(0);
}
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  place-content: center;
  width: 100%;
  background: #fff;
  position: absolute;
  height: 100%;
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: ${appearFromLeft} 1s;
  img {
    width: 105px;
  }
  form {
    margin: 10px 0;
    width: 340px;
    text-align: center;
    h2 {
      margin-bottom: 35px;
      color: #4e4e4e;
    }
    h3 {
      margin-bottom: 20px;
      color: #4e4e4e;
    }
    a {
      color: #808080;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;
      font-size: 14px;
      &:hover {
        color: ${shade(0.2, '#808080')};
      }
    }
  }
  > a {
    color: #fdc000;
    display: block;
    margin-top: 24px;
    text-decoration: none;
    transition: color 0.2s;
    display: flex;
    align-items: center;
    &:hover {
      color: ${shade(0.2, '#fdc000')};
    }
    svg {
      margin-right: 16px;
    }
  }
`;
export const Background = styled.div`
  flex: 1;
  background: #fff;
  background-size: cover;
`;
