import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Button, Modal, Form, Input, Radio, Tooltip, InputNumber, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import API from '../../services/api';

const CollectionCreateForm = ({ visible, onCreate, onCancel, element }) => {

  const [form] = Form.useForm();

  return (
    <Modal
      visible={visible}
      title="Cadastrar novo produto"
      okText="Salvar"
      cancelText="Cancelar"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validação dos campos falhou:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="nome"
          label="Nome"
        >
          <Input defaultValue={element.element.nome} />
        </Form.Item>

        <Form.Item
          name="imagem"
          label="URL Imagem"
        >
          <Input type="textarea" defaultValue={element.element.imagem} />
        </Form.Item>

        <Form.Item
          name="valor_pontos"
          label="Valor de pontos"
        >
          <InputNumber defaultValue={element.element.valor_pontos} />
        </Form.Item>

        <Form.Item
          name="position"
          label="Alinhamento"
          className="collection-create-form_last-form-item"
        >
          <Radio.Group defaultValue={element.element.position}>
            <Radio value="e">Esquerda</Radio>
            <Radio value="d">Direita</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const CollectionsPage = (element) => {

  const [visible, setVisible] = useState(false);

  const onCreate = async (values) => {
    try {

      await API.put('/produtos/' + element.element.id, values)
      message.success('Produto Atualizado com sucesso');

    } catch (error) {
      message.error('Não foi possível atualizar o produto');
      console.log(error)
    }
    setVisible(false);
  };

  return (
    <>
      <Tooltip title="Editar Produto">
        <Button type="primary" style={{ background: "#00562a", border: 0 }} icon={<EditOutlined />} onClick={() => { setVisible(true); }} />
      </Tooltip>

      <CollectionCreateForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
        element={element}
      />
    </>
  );
}

export default CollectionsPage;
