import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import 'antd/dist/antd.css';
import './index.css';
import { Layout, Menu, Breadcrumb } from 'antd';
import {
  NotificationOutlined,
  CreditCardOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import Clientes from '../../pages/Pontos';
import Resgates from '../../pages/Resgates';
import Produtos from '../../pages/Produtos';
import Push from '../../pages/Push';
import logomarca from '../../assets/logo.png';
import ButtonExit from './exit.tsx'

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;
export default class SideBar extends React.Component {

  state = {
    collapsed: false,
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {

    return (
      <Router>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            style={{ background: '#003017' }}
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          >
            <div className="tabMenu">
              {React.createElement(
                this.state.collapsed ? MenuOutlined : MenuOutlined,
                {
                  className: 'trigger',
                  onClick: this.toggle,
                },
              )}
            </div>
            <Menu
              style={{ background: '#003017' }}
              defaultSelectedKeys={['1']}
              mode="inline"
            >
              <SubMenu
                className="subMenu"
                key="sub1"
                icon={<CreditCardOutlined />}
                title="Campelo Card +"
              >
                <Menu.Item
                  style={{ background: '#005328', margin: 0, color: '#ffff' }}
                  key="1"
                >
                  <Link
                    style={{ background: '#005328', margin: 0, color: '#ffff' }}
                    to={"/campelocard/clientes"}
                  >
                    Clientes
                  </Link>
                </Menu.Item>

                <Menu.Item
                  style={{ background: '#005328', margin: 0, color: '#ffff' }}
                  key="2"
                >
                  <Link
                    style={{ background: '#005328', margin: 0, color: '#ffff' }}
                    to="/campelocard/resgates"
                  >
                    Resgates
                  </Link>
                </Menu.Item>

                <Menu.Item
                  style={{ background: '#005328', margin: 0, color: '#ffff' }}
                  key="3"
                >
                  <Link
                    style={{ background: '#005328', margin: 0, color: '#ffff' }}
                    to="/campelocard/produtos"
                  >
                    Produtos
                  </Link>
                </Menu.Item>
              </SubMenu>

              <Menu.Item
                style={{ background: '#003017', margin: 0, color: '#ffff' }}
                key="6"
                icon={<NotificationOutlined />}
              >
                <Link
                  style={{
                    background: '#003017',
                    margin: 0,
                    color: '#ffff',
                    padding: 0,
                  }}
                  to="/push"
                  className="link"
                >
                  Push Notification
                </Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header className="header">
              <img src={logomarca} className="logo" alt='Campelo' />
              <ButtonExit />
            </Header>
            <Content style={{ margin: '0 5px' }}>
              <Breadcrumb style={{ margin: '5px 0' }} />
              <div
                className={"site-layout-background"}
                style={{ padding: 10, minHeight: '100%' }}
              >
                <Switch>
                  <Route path={"/campelocard/clientes"} exact component={Clientes} />
                  <Route
                    path="/campelocard/resgates"
                    exact
                    component={Resgates}
                  />
                  <Route
                    path="/campelocard/produtos"
                    exact
                    component={Produtos}
                  />
                  <Route path="/push" exact component={Push} />
                  <Clientes />
                </Switch>
              </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>Campelo © 2021</Footer>
          </Layout>
        </Layout>
      </Router>
    );
  }
}
