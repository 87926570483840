import React from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { List, Avatar, Button, Tooltip, message } from 'antd';
import { RedoOutlined } from '@ant-design/icons';
import API from '../../services/api';
import Novo from './novoProduto';
import EditProduto from './editProduto';

export default class Produtos extends React.Component {

  state = {
    produtos: [],
  };

  async componentDidMount() {
    try {

      const responseProdutos = await API.get('/produtos/retaguarda');
      this.setState({ produtos: responseProdutos.data });

    } catch (error) {
      message.error('Não foi possível resgatar os dados, verifique a conexão com servidor e tente novamente')
      console.log(error);
    }

  }

  render() {
    return (
      <>
        <div style={{ paddingLeft: 70, paddingRight: 70 }}>
          <Tooltip title="Atualizar">
            <Button onClick={() => this.componentDidMount()} type="primary" style={{ background: "#fdc000", border: 0, marginRight: 10 }} icon={<RedoOutlined />} />
          </Tooltip>
          <Novo />
          <List
            itemLayout="horizontal"
            dataSource={this.state.produtos}
            renderItem={item => (
              <List.Item actions={[
                <EditProduto element={item} />
              ]}>
                <List.Item.Meta
                  avatar={<Avatar size={100} src={item.imagem} shape="square" />}
                  title={item.nome}
                  description={"Valor: " + item.valor_pontos + " Pontos / Alinhamento: " + item.position}
                />
              </List.Item>
            )}
          />
        </div>
      </>
    );
  }
}
