import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body {
    background: #fff;
    -webkit-font-smoothing: antialiased
  }

  body, input, button {
    font-family:"Roboto", serif;
    font-size:  16px;
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
  }

  .sidenav {
    width: 240px;
    background: #00562a;
    position: absolute;
    height: 95vh;
    padding: 0 30px;
    left: -100%;
    transition: all 0.3s;
  }

  .sidenav.active {
    left: 0;
  }

  ul {
    padding: 0;
  }

  ul li {
    list-style-type: none;
    margin: 10px 0;
  }

  ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 16px;
  display: block;
  padding: 10px 15px;
  border-radius: 6px;
  }

  ul li a:hover,
  ul li a.active {
    background: #bb8f00;
    cursor: pointer;
  }

  header svg {
    color: #fff;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
`;
