import React from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Form, Input, Button, message } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import API from '../../services/api';
export default class Push extends React.Component {

  render() {

    const layout = {
      labelCol: {
        span: 5,
      },
      wrapperCol: {
        span: 12,
      },
    };

    const validateMessages = {
      required: 'Campo obrigatório',
    };

    const onFinish = async (values) => {

      try {

        await API.post('/push/', values);
        message.success('Mensagem enviada com sucesso! :)')

      } catch (error) {
        message.error('Não foi possível enviar o PUSH, verifique e tente novamente')
        console.log(error)
      }

    };

    return (
      <>
        <Form style={{ marginTop: 40 }} {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>

          <Form.Item
            name={['titulo']}
            label="Titulo"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name={['mensagem']}
            label="Mensagem"
            rules={[
              {
                required: true,
              },
            ]}>
            <Input.TextArea />
          </Form.Item>

          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 15 }}>
            <Button type="primary" htmlType="submit" icon={<SendOutlined />}>
              Enviar
            </Button>
          </Form.Item>

        </Form>
      </>
    );
  }
}
