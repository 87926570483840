import React from 'react';
import 'antd/dist/antd.css';
import { Table, Input, Button, Space, Row, Col, Card, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import API from '../../services/api';
import Moment from 'react-moment';
import Modal from './modalExtrato';

export default class Clientes extends React.Component {

  state = {
    searchText: '',
    searchedColumn: '',
    clientes: [],
    loadings: [],
    pontos: [],
  };

  async componentDidMount() {
    try {

      const responsePontos = await API.get('/pontos/retaguarda');
      this.setState({ pontos: responsePontos.data });

      const responseClientes = await API.get('/users/retaguarda');
      this.setState({ clientes: responseClientes.data });

    } catch (error) {
      message.error('Não foi possível resgatar os dados, verifique a conexão com servidor e tente novamente')
      console.log(error);
    }

  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Pesquisar`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Buscar
          </Button>
          <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#e6e6e6', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleChange(value) {
  };

  render() {

    const columns = [
      {
        title: 'CPF',
        dataIndex: 'cpf',
        key: 'cpf',
        ...this.getColumnSearchProps('cpf'),
      },
      {
        title: 'Nome',
        dataIndex: 'nome',
        key: 'nome',
        ...this.getColumnSearchProps('nome'),
      },
      {
        title: 'Sobrenome',
        key: 'sobrenome',
        dataIndex: 'sobrenome',
        ...this.getColumnSearchProps('sobrenome'),
      },
      {
        title: 'Contato',
        dataIndex: 'contato',
        key: 'contato',
        ...this.getColumnSearchProps('contato'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Data Nascimento',
        dataIndex: 'dataNascimento',
        key: 'dataNascimento',
        ...this.getColumnSearchProps('dataNascimento'),
        render: (text, resgate) =>
        (
          <>
            <Moment format="DD/MM/YYYY">{resgate.dataNascimento}</Moment>
          </>
        ),
      },
      {
        render: (text, resgate) => (
          <>
            <Modal info={resgate} />
          </>
        ),
      },
    ];

    return (
      <>
        <div className="site-card-wrapper">
          <Row gutter={10}>
            <Col span={5}>
              <Card title="ATIVOS" style={{ textAlign: 'center', fontSize: 18 }} bordered={true} headStyle={{ opacity: 10, background: '#0876FF98' }}>
                {this.state.pontos[0]}
              </Card>
            </Col>
            <Col span={5}>
              <Card title="PENDENTES" style={{ textAlign: 'center', fontSize: 18 }} bordered={true} headStyle={{ background: '#fdc00098' }}>
                {this.state.pontos[1]}
              </Card>
            </Col>
            <Col span={5}>
              <Card title="RESGATADOS" style={{ textAlign: 'center', fontSize: 18 }} bordered={true} headStyle={{ background: '#00562a98' }}>
                {this.state.pontos[2]}
              </Card>
            </Col>
            <Col span={5}>
              <Card title="EXPIRADOS" style={{ textAlign: 'center', fontSize: 18 }} bordered={true} headStyle={{ background: '#FF1E0F98' }}>
                {this.state.pontos[3]}
              </Card>
            </Col>
            <Col span={4}>
              <Card title="TOTAL" style={{ textAlign: 'center', fontSize: 18 }} bordered={true} headStyle={{ background: '#bb8f0098' }}>
                {this.state.pontos[4]}
              </Card>
            </Col>
          </Row>
        </div>
        <Table columns={columns} dataSource={this.state.clientes} />
      </>
    );
  }
}
