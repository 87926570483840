import React, { useCallback, useRef } from 'react';
import { FiLock } from 'react-icons/fi';
import { AiOutlineUser } from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';

import Input from '../../components/Input';
import Button from '../../components/Button';

import getValidationsErrors from '../../utils/getValidationErrors';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import logo from '../../assets/logo.png';

import { Content, AnimationContainer, Background } from './styles';

interface SignInFormData {
  cpf: string;
  senha: string;
}

const SignIn: React.FC = () => {
  const history = useHistory();
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          cpf: Yup.string().required('Preencha seu CPF'),
          senha: Yup.string().min(8, 'Mínimo 8 caracteres'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({ ...data });
        history.push('/dashboard');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationsErrors(err);
          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Atenção, erro ao autenticar!',
          description: 'Cheque suas credenciais.',
        });
      }
    },
    [history, signIn, addToast],
  );

  return (
    <>
      <Content>
        <AnimationContainer>
          <img src={logo} alt="GoBarber" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h2>Retaguarda Campelo</h2>
            <h3>Faça seu login</h3>

            <Input name="cpf" placeholder="CPF" icon={AiOutlineUser} />
            <Input
              name="senha"
              type="password"
              placeholder="Senha"
              icon={FiLock}
            />
            <Button type="submit">Acessar</Button>

            {/* <Link to="/forgot-password">Esqueci minha senha</Link> */}
          </Form>
        </AnimationContainer>
        <p>Version 1.0.1</p>
      </Content>
      <Background />
    </>
  );
};

export default SignIn;
