import React, { useState } from 'react';
import 'antd/dist/antd.css';
import './index.css';
import { Button, Modal, Form, Input, Radio, Tooltip, InputNumber, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import API from '../../services/api';

const CollectionCreateForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  return (
    <Modal
      visible={visible}
      title="Cadastrar novo produto"
      okText="Salvar"
      cancelText="Cancelar"
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validação dos campos falhou:', info);
          });
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="form_in_modal"
        initialValues={{
          modifier: 'public',
        }}
      >
        <Form.Item
          name="nome"
          label="Nome"
          rules={[
            {
              required: true,
              message: 'Campo Obrigatório',
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="imagem"
          label="URL Imagem"
          rules={[
            {
              required: true,
              message: 'Campo Obrigatório',
            },
          ]}>
          <Input type="textarea" />
        </Form.Item>

        <Form.Item
          name="valor_pontos"
          label="Valor de pontos"
          rules={[
            {
              type: 'number',
              min: 0,
              required: true,
              message: 'Campo Obrigatório',
            },
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item
          name="position"
          label="Alinhamento"
          className="collection-create-form_last-form-item"
          rules={[
            {
              required: true,
              message: 'Campo Obrigatório',
            },
          ]}>
          <Radio.Group>
            <Radio value="e">Esquerda</Radio>
            <Radio value="d">Direita</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const CollectionsPage = () => {

  const [visible, setVisible] = useState(false);

  const onCreate = async (values) => {
    try {

      await API.post('/produtos', values)
      message.success('Produto Cadastrado com sucesso');

    } catch (error) {
      message.error('Não foi possível cadastrar o produto')
      console.log(error)
    }
    setVisible(false);
  };

  return (
    <>
      <Tooltip title="Cadastrar novo produto">
        <Button type="primary" style={{ background: "#fdc000", border: 0 }} icon={<PlusOutlined />} onClick={() => { setVisible(true); }} />
      </Tooltip>
      <CollectionCreateForm
        visible={visible}
        onCreate={onCreate}
        onCancel={() => {
          setVisible(false);
        }}
      />
    </>
  );
}

export default CollectionsPage;
